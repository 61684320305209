import { RudderAnalytics } from "@rudderstack/analytics-js";
import { WebConfig } from "~/hooks/webconfig";

let rudderAnalytics: RudderAnalytics | null = null;

function init(webConfig: WebConfig) {
  rudderAnalytics = new RudderAnalytics();
  rudderAnalytics.load(
    webConfig.rudderstackWriteKeyWebApp,
    webConfig.rudderstackURL,
    {},
  );
}

function track(
  event: string,
  extra: { [key: string]: any } = {},
  cb?: () => void,
) {
  if (typeof window == "undefined" || typeof rudderanalytics == "undefined")
    return;
  extra["application"] = "web";
  extra["application_version"] = webConfig.revision;
  rudderanalytics.track(event, extra, cb);
}

function pageView(name: string, extra: { [key: string]: any } = {}) {
  if (typeof window == "undefined" || typeof rudderanalytics == "undefined")
    return;
  extra["application"] = "web";
  extra["application_version"] = webConfig.revision;
  rudderanalytics.page(name, extra);
}
function getUserID(): string {
  if (
    typeof window == "undefined" ||
    typeof rudderanalytics == "undefined" ||
    typeof rudderanalytics.getAnonymousId == "undefined"
  )
    return "";
  return rudderanalytics.getAnonymousId();
}

function experimentStart(name: string, variantID: number, variantName: string) {
  track("experiment_viewed", {
    experiment_name: name,
    experiment_variant: variantName,
    experiment_variant_id: variantID,
  });
}

function addEmail(userID: string, email: string, hasProgram = false) {
  if (typeof window == "undefined" || typeof rudderanalytics == "undefined")
    return;
  if (!userID || !email) {
    return;
  }
  return rudderanalytics.identify(
    userID,
    { email, has_program: hasProgram },
    {},
    () => {},
  );
}

function reset() {
  if (typeof window == "undefined" || typeof rudderanalytics == "undefined")
    return;
  return rudderanalytics.reset(true);
}

const metrics = {
  pageView,
  track,
  getUserID,
  experimentStart,
  addEmail,
  reset,
  init,
};
export default metrics;
